/* Only CSS that can not added using CSS in JS */

/* Node */

.rst__nodeContent {
  display: flex;
  align-items: center;
  right: 0;
}

/* Tree */

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: #ccc;
}

.rst__highlightLineVertical::before {
  background-color: #36c2f6;
}

.rst__highlightLineVertical::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid white;
}

.rst__highlightTopLeftCorner::before {
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
}

.rst__rtl.rst__highlightTopLeftCorner::before {
  border-right: solid 8px #36c2f6;
}

.rst__highlightBottomLeftCorner::before {
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
  border-right: solid 8px #36c2f6;
}

.rst__highlightBottomLeftCorner::after {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
  border-right: 12px solid #36c2f6;
}
